export default {
  methods: {
    dateTime(d) {
      const dt = new Date(d || Date.now());
      const date = dt.toLocaleDateString();
      return [
        new Date().toLocaleDateString() === date ? "Today" : date,
        dt.toLocaleTimeString(),
      ].join(" • ");
    },
  },
};
