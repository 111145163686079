<template>
  <div class="post-container">
    <div class="mobile-bar">
      <div class="button menu" @click="$emit('show-nav')">
        <img src="@/assets/icon-menu.png" />
      </div>
      <h1>{{ currentVenue?.name ?? "Posts" }}</h1>
      <div class="spacer"></div>
      <div class="button chat" @click="$emit('show-chat')">
        <img src="@/assets/icon-chat.png" />
      </div>
    </div>
    <div class="title-post-bar">
      <div
        class="icon"
        :style="{ maskImage: `url(${currentVenue.icon_url})` }"
        v-if="currentVenue?.icon_url"
      ></div>
      <h1>{{ currentVenue?.name ?? "Posts" }}</h1>
      <div class="spacer"></div>
      <div class="post-bar">
        <div class="text">Create a Post</div>
        <div class="post-buttons">
          <img src="@/assets/icon-image.png" />
          <img src="@/assets/icon-video.png" />
          <img src="@/assets/icon-link.png" />
        </div>
      </div>
    </div>
    <div class="top-fade"></div>
    <div ref="posts" class="posts">
      <div class="mobile post-bar">
        <div class="text">Create a Post</div>
        <div class="post-buttons">
          <img src="@/assets/icon-image.png" />
          <img src="@/assets/icon-video.png" />
          <img src="@/assets/icon-link.png" />
        </div>
      </div>
      <post
        :class="{ highlighted: this.highlightedPostId === post.id }"
        :post="post"
        v-for="post in postsForCurrentVenue"
        :key="post.id"
        :ref="'post' + post.id"
        @click="postClicked(post)"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import eventBus from "../eventBus";
import Post from "./Post";
export default {
  components: { Post },
  data() {
    return {
      highlightedPostId: null,
      lastScrollTopByVenueId: {},
      clearHandler: null,
    };
  },
  watch: {
    currentVenueId(newVenueId) {
      const oldVenueOffset = this.lastScrollTopByVenueId[newVenueId];
      this.$nextTick(() => {
        if (oldVenueOffset) {
          setTimeout(() => {
            this.$refs.posts.scrollTop = oldVenueOffset;
          }, 250);
        } else {
          this.$refs.posts.scrollTop = 0;
        }
      });
    },
  },
  mounted() {
    this.clearHandler = () => {
      this.highlightedPostId = null;
    };
    eventBus.$on("clear", this.clearHandler);
    eventBus.$on("connect", ({ message, ref }) => {
      if (message && (this.$refs["post" + message.post_id] || {}).$el) {
        if (this.highlightedPostId !== message.post_id) {
          this.highlightedPostId = message.post_id;
          const commentsEl = (
            this.$refs["post" + message.post_id] || {}
          ).$el.querySelector(".comments");
          this.$refs.posts.scrollTo({
            top:
              commentsEl.getBoundingClientRect().y -
              window.innerHeight / 2 +
              this.$refs.posts.scrollTop,
            left: 0,
            behavior: "smooth",
          });
        }
        this.$nextTick(() => {
          eventBus.$emit("connect2", {
            messageRef: ref,
            postRef: (this.$refs["post" + message.post_id] || {}).$el,
          });
        });
      } else {
        this.highlightedPostId = null;
      }
    });

    this.$refs.posts.addEventListener("scroll", () => {
      this.lastScrollTopByVenueId[
        this.currentVenueId
      ] = this.$refs.posts.scrollTop;
      eventBus.$emit("redraw");
    });
  },
  beforeUnmount() {
    eventBus.$off("clear", this.clearHandler);
  },
  computed: {
    ...mapState(["currentVenueId", "posts"]),
    ...mapGetters(["currentVenue", "currentUserAvatarUrl"]),
    postsForCurrentVenue() {
      return Object.values(this.posts).filter(
        (p) => p.venue_id === this.currentVenueId
      ).sort((x, y) => {
        return x.id - y.id
      });
    },
  },
  methods: {
    postClicked({ id }) {
      if (this.highlightedPostId !== id) {
        this.highlightedPostId = null;
        eventBus.$emit("clear");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.post-container {
  position: relative;
  width: 717px;
  height: 100vh;
  display: flex;
  flex-direction: column;
  --shared-horiz-margin: 40px;
  .posts {
    overflow: auto;
    width: 100%;
    flex: 1 1 auto;
    display: flex;
    height: calc(100% - 90px);
    flex-direction: column;
    position: relative;
    background-color: #f8f8f8;
    padding: 0 var(--shared-horiz-margin);
    > div {
      box-sizing: border-box;
      z-index: 1;
      margin: 20px 0 0 0;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      border-radius: 24px;
      background-color: white;
    }
    .highlighted {
      border: 2px solid #4f4fe4;
    }
  }
  .title-post-bar {
    margin: 20px var(--shared-horiz-margin) 0 var(--shared-horiz-margin);
    display: flex;
    align-items: center;
    h1 {
      font-size: 18px;
      letter-spacing: -0.055em;
      font-weight: 900;
      color: #4f4fe4;
      flex: 0 0 auto;
    }
    > div.icon {
      background-color: #4f4fe4;
      mask-size: 16px 16px;
      height: 16px;
      width: 16px;
      margin-right: 8px;
      flex: 0 0 auto;
    }
    .spacer {
      flex: 1 1 auto;
    }
  }
  .post-bar {
    &.mobile {
      display: none;
    }
    display: flex;
    align-items: center;
    border-radius: 24px;
    height: 50px;
    flex: 0 0 auto;
    box-sizing: border-box;
    padding: 0 16px;
    border-radius: 24px;
    background-color: white;
    max-width: 270px;
    > img {
      margin-right: 10px;
      height: 32px;
      width: 32px;
      object-fit: contain;
      flex: 0 0 auto;
    }
    > div.text {
      flex: 1 1 auto;
      font-size: 18px;
      opacity: 0.7;
    }
    .post-buttons {
      flex: 0 0 auto;

      display: flex;
      align-items: center;
      > img {
        height: 24px;
        width: 24px;
        object-fit: contain;
        margin-left: 15px;
      }
    }
  }
  .mobile-bar {
    display: none;
    margin: 20px var(--shared-horiz-margin);
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 18px;
      letter-spacing: -0.055em;
      font-weight: 900;
      color: #4f4fe4;
      flex: 0 0 auto;
    }
    .spacer {
      flex: 1 1 auto;
    }
    .button {
      width: 24px;
      height: 24px;
      flex: 0 0 auto;
      > img {
        width: 100%;
        height: 100%;
      }
      &.menu {
        margin-right: 8px;
      }
    }
  }
  .top-fade {
    background: linear-gradient(
      180deg,
      #f8f8f8 0%,
      rgba(248, 248, 248, 0) 100%
    );
    position: absolute;
    left: 0;
    right: 0;
    top: 70px;
    height: 40px;
    z-index: 2;
  }
}
@include mobile {
  .post-container {
    --shared-horiz-margin: 20px;
    height: $device-height;
    .title-post-bar {
      display: none;
    }
    .post-bar.mobile {
      display: flex;
      max-width: unset;
      margin-bottom: 0;
    }
    .mobile-bar {
      display: flex;
    }
    .top-fade {
      top: 60px;
    }
  }
}
</style>
