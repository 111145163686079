<template>
  <div class="main">
    <div class="slide" ref="slide">
      <div
        class="nav"
        :class="{
          'mobile-shown': isMobileNavShown,
        }"
      >
        <left-nav />
        <group-nav @venue-selected="isMobileNavShown = false" />
      </div>
      <div class="spacer"></div>
      <posts
        @show-nav="isMobileNavShown = true"
        @show-chat="toggleChat(true)"
      />
      <chat @hide-chat="toggleChat(false)" />
      <attribution />
    </div>
  </div>
</template>

<script>
import LeftNav from "./components/LeftNav.vue";
import GroupNav from "./components/GroupNav.vue";
import Posts from "./components/Posts.vue";
import Chat from "./components/Chat.vue";
import Attribution from "./components/Attribution.vue";
import { mapActions, mapState } from "vuex";
function setVh() {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
}

export default {
  name: "App",
  components: {
    LeftNav,
    GroupNav,
    Posts,
    Chat,
    Attribution,
  },
  data() {
    return {
      isMobileNavShown: null,
    };
  },
  computed: {
    ...mapState(["currentVenueId"]),
  },
  mounted() {
    setVh();
    window.addEventListener("resize", setVh);
  },
  beforeUnmount() {
    window.removeEventListener("resize", setVh);
  },
  watch: {
    currentVenueId: {
      immediate: true,
      handler(id) {
        this.ensureScriptPlaybackStartedForVenue(id);
      },
    },
  },
  methods: {
    ...mapActions(["ensureScriptPlaybackStartedForVenue"]),
    toggleChat(show) {
      this.$refs.slide.scrollTo({
        left: show ? window.innerWidth : 0,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style lang="scss">
:root {
  --vh: 1vh;
}
body {
  background-color: #f8f8f8;
}
#app {
  font-family: -apple-system, BlinkMacSystemFont, Avenir, Helvetica, Arial,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
h1,
body {
  margin: 0;
  padding: 0;
}
div {
  box-sizing: border-box;
}
</style>

<style lang="scss" scoped>
.main {
  position: absolute;
  top: env(safe-area-inset-top, 0);
  left: env(safe-area-inset-left, 0);
  bottom: env(safe-area-inset-bottom, 0);
  right: env(safe-area-inset-right, 0);

  overflow: hidden;
  .slide {
    display: flex;
    justify-content: space-between;
    > div {
      flex: 0 0 auto;
      &.spacer {
        flex: 1 1 auto;
      }
    }
    .nav {
      display: flex;
    }
  }
}
@include mobile {
  .main {
    box-sizing: border-box;
    height: $device-height;
    .nav {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      transition: transform 0.5s;
      transform: translate(-100vw);
      z-index: 10;
      &.mobile-shown {
        transform: translate(0);
      }
    }
    .slide {
      overflow: auto;
      scroll-snap-type: x mandatory;
      transition: transform 0.5s;
      > div {
        scroll-snap-align: center;
        width: 100vw;
      }
    }
  }
}
</style>
