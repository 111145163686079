<template>
  <div class="left-nav">
    <div>
      <img class="top-logo" src="@/assets/cymbal-logo.png" />
      <div
        class="company"
        v-for="(company, index) in companies"
        :key="company.logo_url"
        :class="{ selected: index === 0 }"
      >
        <img :src="company.logo_url" />
      </div>
      <div class="spacer"></div>
      <img class="settings" src="@/assets/icon-settings.png" />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      companies: [
        { logo_url: require("@/assets/company-makeship.png") },
        { logo_url: require("@/assets/company-overwatch.png") },
        { logo_url: require("@/assets/company-hotwheels.png") },
        { logo_url: require("@/assets/company-apple.png") },
        { logo_url: require("@/assets/company-tesla.png") },
      ],
    };
  },
};
</script>

<style lang="scss">
.left-nav {
  background-color: #4f4fe4;
  > div {
    width: 100px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.8);
    .top-logo {
      margin: 16px 0;
      width: 100%;
    }
    .spacer {
      flex: 1 1 auto;
    }
    .settings {
      width: 24px;
      object-fit: contain;
      margin-bottom: 30px;
    }
    .company {
      width: 100%;
      text-align: center;
      margin-top: 24px;
      position: relative;
      &.selected {
        overflow: hidden;
        img {
          width: 72px;
        }
        &::after {
          content: " ";
          display: block;
          background-color: #4f4fe4;
          transform: rotate(-45deg);
          height: 27px;
          width: 27px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -19px;
          margin: auto 0;
        }
      }
      img {
        width: 56px;
        object-fit: contain;
      }
    }
  }
}
</style>
