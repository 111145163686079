import { createStore } from "vuex";
import venueScripts from "./venue_scripts";

export default createStore({
  state: {
    scriptPlaybackIntervals: {},
    currentVenueId: "general",
    currentUserId: 27,
    venues: {
      general: {
        name: "General",
        icon_url: require("@/assets/icon-home.png"),
        id: "general",
      },
      plushes: {
        name: "Products",
        icon_url: require("@/assets/icon-teddy-bear.png"),
        id: "plushes",
      },
      news: {
        name: "Events",
        icon_url: require("@/assets/icon-megaphone.png"),
        id: "news",
        disabled: true,
      },
      special: {
        name: "Creator Updates",
        icon_url: require("@/assets/icon-recycle.png"),
        id: "special",
        disabled: true,
      },
    },
    posts: {
      0: {
        id: 0,
        user_id: 9,
        time: new Date(),
        abovefold: `Check out the new drop!`,
        content: ``,
        hero_image: require("@/assets/product-post.png"),
        hero_image_mobile: require("@/assets/product-post-mobile.png"),
        venue_id: "plushes",
      },
      1: {
        id: 1,
        user_id: 2,
        time: new Date(),
        title: "Our first community contest!",
        abovefold:
          'We\'ve updated our FAQ with the latest info on how to get in on new Makeship community products. Have more questions? Please send us an email ➡️ <a href="mailto:support@makeship.com">support@makeship.com</a>.',
        content: `
          <h2>What is a campaign?</h2>
          A campaign is a typically a 3-4 week window where a product is available for purchase. If a product receives enough orders to hit its goal, the product will be made. If not, everyone will be refunded. 
          
          The product I want is out of stock, when will it become available again?</h2>
          We don't make re-runs of products, so it's best to place your order during the campaign period. In some rare occasions we launch new variations or ultra-limited edition versions of previous products. If you'd like a certain product to be brought back, your best bet is to let the creator know by messaging them!
          
          <h2>Why does it take soooo long for my product to ship?</h2>
          Products that reach their funding goal are put into production. Since we have to make thousands of plushies, they can take up to 90 days to cook up! Thank you for your patience while we get your product ready.
          
          <h2>When will my order ship?</h2>
          Your order will ship 1-2 business days after production of the item is completed. You will receive an email when this occurs. Shipping typically takes 7-15 business days depending on your country and city of origin.
          
          <h2>How long does production take?</h2>
          Production time depends on the product ordered and is unique to each campaign. Please check the product description of your specific product/campaign page for details.
          
          <h2>Are there additional costs upon delivery (customs, duties, taxes)?</h2>
          Unfortunately, we cannot take responsibility for additional charges imposed by your government upon receiving any product. This is very much out of our control and we are unable to foresee any of these costs. Please contact your local mailing service provider for specific details.
          
          <h2>Can I cancel my order?</h2>
          Yes! Orders can be cancelled at any point before the end of the campaign. Please email us at support@makeship.com with your order number if you would like to cancel your order.
          
          <h2>What happens if my item arrives damaged?</h2>
          Please send pictures of the damaged item and your order number to support@makeship.com and we will happily help you.
          
          <h2>Can I create my own campaign with Makeship?</h2>
          If you are an influencer interested in running a campaign, please contact support@makeship.com for further information.
          
          <h2>What is your website's currency?</h2>
          All prices on our website are in USD. You can still use an international card, the payment will then be drafted to its USD value.
          
          <h2>Do you ship to my country? How much?</h2>
          We ship worldwide with a flat rate of 7.99 USD and 3.49 USD on every additional item. Note: Due to the Corona Virus outbreak, delivery to some countries is temporarily unavailable.
          
          To check, you can try adding the item to your cart, proceed to checkout and enter your address. This does not, in any way, commit you into making a purchase. 
          
          <h2>What is your Returns Policy?</h2>
          All sales are final and non-refundable unless the item received is damaged.
          `,
        hero_image: require("@/assets/post-1-hero.png"),
        venue_id: "general",
      },
      2: {
        id: 2,
        user_id: 9,
        time: new Date(),
        abovefold: `We just released a whole new set of Makeship badges! You’ll collect a unique badge whenever you support a Makeship campaign. Check mine out here: <a href="https://www.makeship.com/badges/user/pablo">https://www.makeship.com/badges/user/pablo</a>`,
        content: ``,
        hero_image: require("@/assets/post-2-hero.png"),
        venue_id: "general",
      },
      3: {
        id: 3,
        user_id: 10,
        time: new Date(),
        abovefold: `<p>Oooh, look what 3 campaigns just got funded!</p>
        <ul>
          <li>iJevin: <a href="https://www.makeship.com/products/ijevin-limited-edition-green-plush">https://www.makeship.com/products/ijevin-limited-edition-green-plush</a></li>
          <li>Lucia: <a href="https://www.makeship.com/products/lucia-plush">https://www.makeship.com/products/lucia-plush</a></li>
          <li>AxeMan: <a href="https://www.makeship.com/products/axeman-plush">https://www.makeship.com/products/axeman-plush</a></li>
        </ul>
        `,
        content: ``,
        hero_image: require("@/assets/post-3-hero.png"),
        venue_id: "general",
      },
      4: {
        id: 4,
        user_id: 7,
        time: new Date(),
        abovefold: `Big news to share this week! We are introducing, for the first time ever, our newest product line... CHARMS<br><br>
        Makeship creators will be able to design their own charms, which will be 18K Gold plated with a sterling silver base and a hardened enamel graphic. Charms can be linked to an adjustable 18K White Gold Plated bracelet, worn on a necklace, or clipped to a keychain so you can take your favorite creator with you wherever you go. Every limited edition Charm includes a tag indicating exactly how many were created!
      
        Drop some fiya emojis if you're as excited as we are for CHARMS `,
        content: ``,
        hero_image: require("@/assets/post-3-hero.png"),
        venue_id: "plushes",
      },
      5: {
        id: 5,
        user_id: 2,
        time: new Date(),
        abovefold: `
        Hi hi <span class="blue">@Legends @MVP @All-Stars</span> & <span class="blue">@Chosen</span>, it is time for our weekly spoiler in which we exclusively share a sneak peek of campaigns launching tonight. This week's drop:
        <br><br>Cherry Plush<br>
        Sister Sister Plush <br>
        Ready to Glare Plush <br>
        Loofah Puppet Plush <br>
        The Dunkin Ducks Plush<br>
        <br>
      <br>The campaigns featured will be available Oct 3rd at 12:01 am GMT on makeship.com.
        
        `,
        content: ``,
        hero_image: require("@/assets/post-5-hero.png"),
        venue_id: "plushes",
      },
      6: {
        id: 6,
        user_id: 6,
        time: new Date(),
        abovefold: `
Hello Hello everyone, I hope you all are having a fantastic weekend! <br>
<br>
We're currently working on a way to automate the process of updating your membership roles (<span class="blue">@Chosen</span> , <span class="blue">@All-Stars</span> , <span class="blue">@MVP</span>). Currently your membership roles represent the degree to which you've supported Makeship campaigns, and the company as a whole. That said, I know that since me being here a lot of you have continued to support us, leaving your roles out of date. Please answer this 3 question short form in order to have your membership role updated! <br>
<br>
<a href="https://forms.gle/e8qozFmZ51MBJSYa6">https://forms.gle/e8qozFmZ51MBJSYa6</a><br>
<br>
Ps. Even if you're currently an <span class="tag"@MVP</span> member, please fill out the form as there are new roles you may be qualified for!
        `,
        content: ``,
        hero_image: null,
        venue_id: "plushes",
      },
      7: {
        id: 7,
        user_id: 7,
        time: new Date(),
        abovefold: `
        <h2>🏆 FIYA FAN ART CONTEST 🏆</h2>
        To celebrate the first week into the summer season, we are hosting our first fan art contest.<br>
        To enter, all you have to do is: <br>
        🔥 Draw our Fiya Plush in your own style (hand drawings and digital versions are both accepted)<br>
        🔥 Post your illustration on the #contest-and-giveaways channel by Sunday, July 4th 11:59 pm (GMT)
        <br><br>
        Illustrations will be judged based on creativity and individuality — we want you to make Fiya your own #FuelYourFiya. 
        <br><br>
        TWO ways to WIN: <br>
        1️⃣ We will be giving away 1 Fiya Plush to the best submission <br>
        2️⃣ Each participant's submission will earn 1 entry into Fiya Plush raffle (one submission per person)        <br>
        `,
        content: ``,
        hero_image: null,
        venue_id: "general",
      },
      '-1': {
        id: -1,
        user_id: 1,
        time: new Date(),
        abovefold: `
        <p>Can I get some feedback on this animation for our newest creation? How does the movement look?</p>
        <video controls playsinline src="${require("@/assets/tail.mp4")}"></video>
        `,
        content: ``,
        hero_image: null,
        venue_id: "plushes",
      },
    },
    users: {
      1: {
        name: "Ray",
        pic_url: require("@/assets/viewer-avatar.png"),
      },
      2: {
        name: "brenda",
        pic_url: require("@/assets/avatar-melissa.png"),
      },
      3: {
        name: "Albydog73",
        pic_url: require("@/assets/avatar-corey.png"),
      },
      4: {
        name: "CapDanX",
        pic_url: require("@/assets/avatar-gina.png"),
      },
      5: {
        name: "Letty",
        pic_url: require("@/assets/avatar-jesse.png"),
      },
      6: {
        name: "Jamal Bryan",
        pic_url: require("@/assets/avatar-nova.png"),
      },
      7: {
        name: "Rund",
        pic_url: require("@/assets/avatar-sylvan.png"),
      },
      8: {
        name: "Alex",
        pic_url: require("@/assets/avatar-tara.png"),
      },
      9: {
        name: "pablo2",
        pic_url: require("@/assets/avatar-pablo.png"),
      },
      10: {
        name: "Gekko",
        pic_url: require("@/assets/avatar-gekko.png"),
      },
      11: {
        name: "springlockfailure",
        pic_url: require("@/assets/avatar-corey.png"),
      },
      12: {
        name: "IG-88",
        pic_url: require("@/assets/avatar-nova.png"),
      },
      13: {
        name: "MysticArt12",
        pic_url: require("@/assets/avatar-mysticart12.png"),
      },
      14: {
        name: "Aoife The Awesomest",
        pic_url: require("@/assets/avatar-melissa.png"),
      },
      15: {
        name: "IcyDreemurr",
        pic_url: require("@/assets/avatar-icydreemur.png"),
      },
      16: {
        name: "redfailhawk",
        pic_url: require("@/assets/avatar-redfailhawk.png"),
      },
      17: {
        name: "Tony_INK",
        pic_url: require("@/assets/avatar-17.png"),
      },
      18: {
        name: "Gandaron",
        pic_url: require("@/assets/avatar-18.png"),
      },
      19: {
        name: "Zensanna",
        pic_url: require("@/assets/avatar-19.png"),
      },
      20: {
        name: "Gracelander101",
        pic_url: require("@/assets/avatar-20.png"),
      },
      21: {
        name: "HyperSindra",
        pic_url: require("@/assets/avatar-21.png"),
      },
      22: {
        name: "Albydog73",
        pic_url: require("@/assets/avatar-22.png"),
      },
      23: {
        name: "MJMGaming",
        pic_url: require("@/assets/avatar-23.png"),
      },
      24: {
        name: "Spellweaver",
        pic_url: require("@/assets/avatar-24.png"),
      },
      25: {
        name: "vrgamer40",
        pic_url: require("@/assets/avatar-25.png"),
      },
      26: {
        name: "NokyaTheRobot",
        pic_url: require("@/assets/avatar-tony.png"),
      },
      27: {
        name: "Eric",
        pic_url: require("@/assets/avatar-eric.png"),
      },
    },
    // messages are chats and post comments.
    // always have user_id, time, and venue_id
    // if they are attached to post, they have a post_id
    messages: [],
    // venue_id -> user_id
    typingUserIds: {},
    // venue_id -> post_id -> user_id
    typingUserIdsInPostIds: {},
  },
  mutations: {
    setCurrentVenueId(state, venueId) {
      state.currentVenueId = venueId;
    },
    createMessage(state, payload) {
      state.messages.push({
        id: Math.random(),
        post_id: payload.post_id,
        text: payload.text,
        user_id: state.currentUserId,
        venue_id: state.currentVenueId,
        time: new Date(),
      });
    },
    addMessage(state, message) {
      // clear any typing status
      const { post_id, venue_id, user_id } = message;
      if (
        post_id &&
        state.typingUserIdsInPostIds[venue_id] &&
        state.typingUserIdsInPostIds[venue_id][post_id]
      ) {
        state.typingUserIdsInPostIds[venue_id][post_id][user_id] = false;
      } else if (state.typingUserIds[venue_id]) {
        state.typingUserIds[venue_id][user_id] = false;
      }

      state.messages.push(message);
      state.messages.sort(({ time: a }, { time: b }) => a - b);
    },
    setScriptPlaybackInterval(state, { venueId, interval }) {
      if (state.scriptPlaybackIntervals[venueId]) {
        clearInterval(state.scriptPlaybackIntervals[venueId]);
        delete state.scriptPlaybackIntervals[venueId];
      }
      state.scriptPlaybackIntervals[venueId] = interval;
    },
    setUserIsTyping(state, { venue_id, user_id }) {
      if (venue_id in state.typingUserIds) {
        state.typingUserIds[venue_id][user_id] = true;
      } else {
        state.typingUserIds[venue_id] = { [user_id]: true };
      }
    },
    setUserIsTypingInPostId(state, { venue_id, post_id, user_id }) {
      if (venue_id in state.typingUserIdsInPostIds) {
        if (post_id in state.typingUserIdsInPostIds[venue_id]) {
          state.typingUserIdsInPostIds[venue_id][post_id][user_id] = true;
        } else {
          state.typingUserIdsInPostIds[venue_id][post_id] = { [user_id]: true };
        }
      } else {
        state.typingUserIdsInPostIds[venue_id] = {
          [post_id]: { [user_id]: true },
        };
      }
    },
  },
  actions: {
    ensureScriptPlaybackStartedForVenue({ commit, state }, venueId) {
      const events = venueScripts[venueId];
      if (events.length === 0 || state.scriptPlaybackIntervals[venueId]) {
        return;
      }
      const startTime = new Date();
      events.forEach((event) => {
        event.time = new Date(startTime.getTime() + event._timeOffset);
      });
      const interval = setInterval(() => {
        while (events && events.length > 0 && eventReady(events[0])) {
          sendAfterTyping(events.shift());
        }
      }, 1000);
      commit("setScriptPlaybackInterval", { venueId, interval });
      function typingTime({ time, text }) {
        return time < startTime ? 0 : Math.min(2, text.trim().length * 0.2);
      }
      function eventReady(event) {
        return event.time - new Date() < typingTime(event) * 1000;
      }
      function sendAfterTyping(event) {
        const delay = typingTime(event);
        if (delay === 0) {
          commit("addMessage", event);
        } else {
          if (event.post_id) {
            commit("setUserIsTypingInPostId", event);
          } else {
            commit("setUserIsTyping", event);
          }
          setTimeout(() => commit("addMessage", event), delay * 1000);
        }
      }
    },
  },
  getters: {
    currentUserAvatarUrl(state) {
      return state.users[state.currentUserId].pic_url;
    },
    currentVenue(state) {
      return state.venues[state.currentVenueId] ?? null;
    },
  },
});
