<template>
  <div class="chat-bar">
    <img :src="currentUserAvatarUrl" />
    <div class="input-bar">
      <input
        type="text"
        v-model="text"
        placeholder="Type your message"
        @keyup.enter="onSubmit"
      />
      <img src="@/assets/input-bar-icons.png" />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from "vuex";
export default {
  props: ["post_id"],
  data() {
    return {
      text: "",
    };
  },
  computed: {
    ...mapGetters(["currentUserAvatarUrl"]),
  },
  methods: {
    ...mapMutations(["createMessage"]),
    onSubmit() {
      if (this.text.trim().length === 0) {
        return;
      }
      this.createMessage({
        post_id: this.post_id,
        text: this.text,
      });
      this.text = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.chat-bar {
  display: flex;
  align-items: center;
  height: 38px;
  > img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    flex: 0 0 auto;
    margin-right: 8px;
  }
  width: 100%;
  .input-bar {
    flex: 1 1 auto;
    background-color: rgba(0, 0, 0, 0.05);
    border-radius: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    input {
      flex: 1 1 auto;
      margin: 10px 12px;
      background-color: transparent;
      border: none;
      outline: none;
    }
    > img {
      height: 24px;
      object-fit: contain;
      margin-right: 12px;
    }
  }
}
</style>
