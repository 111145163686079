<template>
  <div class="indicator">
    <div></div>
    <div></div>
    <div></div>
  </div>
</template>

<style lang="scss" scoped>
.indicator {
  display: flex;
  height: 20px;
  align-items: center;
  > div {
    border-radius: 100%;
    width: 6px;
    height: 6px;
    background-color: black;
    margin-right: 4px;
    animation-name: bounce;
    animation-fill-mode: both;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    // &:nth-child(1) {
    // }
    &:nth-child(2) {
      animation-delay: 0.3s;
    }
    &:nth-child(3) {
      animation-delay: 0.5s;
    }
  }
}
@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: 0.5;
  }
  33% {
    transform: translateY(4px);
  }
  50% {
    opacity: 0.1;
  }
  66% {
    transform: translateY(-4px);
  }
  100% {
    transform: translateY(0px);
    opacity: 0.5;
  }
}
</style>
