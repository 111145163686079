<template>
  <div class="comment">
    <img class="avatar" :src="user.pic_url" />
    <div class="bubble-attribution">
      <div
        class="bubble"
        v-if="message.is_typing || (message.text?.trim().length ?? 0) > 0"
        :class="{ 'post-message': messageType === 'post', highlighted }"
      >
        <chat-typing-indicator v-if="message.is_typing" />
        <template v-else>
          {{ message.text }}
        </template>
      </div>
      <img
        class="attachment"
        v-if="message.image_url"
        :src="message.image_url"
      />
      <div class="attribution">
        <span class="name">{{ user.name }}</span>
        <span class="date" v-if="message.time">{{
          dateTime(message.time)
        }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import formatters from "../formatters";
import ChatTypingIndicator from "./ChatTypingIndicator.vue";
import { mapState } from "vuex";
export default {
  components: {
    ChatTypingIndicator,
  },
  props: {
    message: {
      type: Object,
    },
    messageType: {
      type: String,
      default: null,
    },
    highlighted: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [formatters],
  computed: {
    ...mapState(["users"]),
    user() {
      return this.users[this.message.user_id];
    },
  },
};
</script>

<style lang="scss" scoped>
.comment {
  margin: 10px 10px;
  display: flex;
  margin-top: 8px;
  img.avatar {
    height: 32px;
    width: 32px;
    margin-right: 8px;
  }
  img.attachment {
    height: 80px;
    margin-top: 6px;
  }
  .bubble-attribution {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    .bubble {
      font-size: 13px;
      &.post-message {
        background-color: #f2f2f2;
        &.highlighted {
          background-color: #edffdf;
        }
      }
      background-color: white;
      border-radius: 14px;
      padding: 6px 12px;
    }
    .attribution {
      margin: 2px 0 0 6px;
      display: flex;
      opacity: 0.5;
      align-items: baseline;
      .name {
        font-size: 11px;
        font-weight: bold;
        margin-right: 4px;
      }
      .date {
        font-size: 10px;
      }
    }
  }
}
</style>
