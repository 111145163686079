<template>
  <div class="group-nav">
    <img class="logo" src="@/assets/header-logo.png" />
    <img src="@/assets/makeship-banner.png" />
    <img src="@/assets/x-mark-32.png" class="close" @click="close" />
    <div class="group-name">
      <h1>Makeship</h1>
      <div class="description">This is a comunity for Makeship creators</div>
      <div class="stats">829 Online • 2,301 Members</div>
    </div>
    <div class="search">
      <img src="@/assets/icon-search.png" />
      <span>Search Makeship</span>
    </div>
    <div class="venues">
      <div
        class="venue"
        v-for="venue in venues"
        :key="venue.id"
        @click="updateVenue(venue)"
        :class="{ selected: currentVenueId === venue.id }"
      >
        <img :src="venue.icon_url" />
        <span>{{ venue.name }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import eventBus from "../eventBus";
export default {
  computed: {
    ...mapState(["venues", "currentVenueId"]),
  },
  methods: {
    ...mapMutations(["setCurrentVenueId"]),
    updateVenue(venue) {
      if (!venue.disabled) {
        this.setCurrentVenueId(venue.id);
        eventBus.$emit("clear");
        this.$emit("venue-selected");
      }
    },
    close() {
        eventBus.$emit("clear");
        this.$emit("venue-selected");

    }
  },
};
</script>

<style lang="scss" scoped>
.group-nav {
  width: 304px;
  img.close {
    display: none;
  }
  @include mobile {
    width: unset;
    img.close {
      display: block;
      cursor: pointer;
      position: absolute;
      right: 20px;
      top: 24px;
      height: 20px;
      width: 20px;
    }

  }
  background-color: #4f4fe4;
  display: flex;
  flex-direction: column;
  > img {
    &.logo {
      width: 48px;
      margin: 20px 0 0 22px;
    }
    width: 100%;
    object-fit: contain;
  }
  .group-name {
    margin: 20px 0 0 22px;
    color: white;
    h1 {
      font-size: 28px;
      font-weight: 900;
    }
    > div {
      margin-top: 4px;
      font-size: 12px;
      &.description {
        opacity: 0.7;
      }
    }
  }
  .search {
    margin: 20px 22px;
    display: flex;
    align-items: center;
    background-color: white;
    border-radius: 100px;
    font-size: 14px;
    padding: 8px 11px;
    height: 34px;
    img {
      height: 18px;
      object-fit: contain;
      margin-right: 4px;
    }
    span {
      opacity: 0.5;
      white-space: nowrap;
    }
  }
  .venues {
    .venue {
      cursor: pointer;
      display: flex;
      align-items: center;
      color: white;
      height: 40px;
      margin-bottom: 4px;
      img {
        margin: 0 8px 0 21px;
        height: 18px;
      }
      font-weight: bold;
      opacity: 0.6;
      &.selected {
        font-weight: 900;
        opacity: 1;
        background-color: rgba(0, 0, 0, 0.2);
        overflow: hidden;
        position: relative;
        &::after {
          content: " ";
          display: block;
          background-color: white;
          transform: rotate(-45deg);
          height: 27px;
          width: 27px;
          position: absolute;
          top: 0;
          bottom: 0;
          right: -19px;
          margin: auto 0;
        }
        @include mobile {
          &::after {
            display: none;
          }
        }
      }
    }
  }
}
</style>
