<template>
  <canvas
    :width="currentWidth"
    :height="currentHeight"
    ref="main"
    class="attribution-area"
  />
</template>

<script>
import eventBus from "../eventBus";

export default {
  data() {
    return {
      currentConnection: null,
      currentWidth: window.innerWidth,
      currentHeight: window.innerHeight,
      currentMessage: null,
      currentPost: null,
      resizeHandler: null,
    };
  },
  mounted() {
    eventBus.$on("clear", () => {
      this.currentMessage = null;
      this.currentPost = null;
      this.redraw();
    });
    eventBus.$on("redraw", () => {
      this.redraw();
    });
    eventBus.$on("connect2", ({ messageRef, postRef }) => {
      this.currentPost = postRef;
      this.currentMessage = messageRef;
      this.redraw();
    });
    this.resizeHandler = this.resize.bind(this);
    window.addEventListener("resize", this.resizeHandler);
  },
  beforeUnmount() {
    if (this.resizeHandler) {
      window.removeEventListener("resize", this.resizeHandler);
      this.resizeHandler = null;
    }
  },
  methods: {
    resize() {
      this.currentWidth = window.innerWidth;
      this.currentHeight = window.innerHeight;
      this.redraw();
    },
    redraw() {
      const ctx = this.$refs.main.getContext("2d");
      ctx.clearRect(0, 0, this.currentWidth, this.currentHeight);
      if (this.currentPost && this.currentMessage) {
        let postRect = this.currentPost.getBoundingClientRect();
        let messageRect = this.currentMessage.getBoundingClientRect();
        ctx.beginPath();
        ctx.moveTo(
          postRect.x + postRect.width,
          postRect.y + postRect.height / 2
        );
        ctx.bezierCurveTo(
          messageRect.x,
          postRect.y + postRect.height / 2,
          postRect.x + postRect.width,
          messageRect.y + messageRect.height / 2,
          messageRect.x,
          messageRect.y + messageRect.height / 2
        );
        ctx.stroke();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attribution-area {
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  top: 0px;
  overflow: hidden;
  height: 100vh;
  z-index: 3;
  pointer-events: none;
}
@include mobile {
  .attribution-area {
    display: none;
  }
}
</style>
