<template>
  <div class="grouped">
    <post-info :post="posts[message.post_id]" />
    <chat-message
      :message="message"
      message-type="post"
      :highlighted="false"
      v-if="message.user_id"
    />
    <chat-message
      :message="{ user_id, is_typing: true }"
      message-type="post"
      :highlighted="false"
      v-for="user_id in userIdsTyping"
      :key="user_id"
    />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ChatMessage from "./ChatMessage";
import PostInfo from "./PostInfo";
export default {
  components: { ChatMessage, PostInfo },
  props: {
    message: { type: Object },
  },
  computed: {
    ...mapState(["posts", "typingUserIdsInPostIds", "currentVenueId"]),
    userIdsTyping() {
      return Object.entries(
        (this.typingUserIdsInPostIds[this.currentVenueId] ?? {})[
          this.message.post_id
        ] ?? {}
      )
        .filter((v) => v[1])
        .map((v) => v[0]);
    },
  },
};
</script>

<style lang="scss" scoped>
.grouped {
  border-radius: 14px;
  background-color: white;
  padding: 15px;
  box-sizing: border-box;
  cursor: pointer;
  margin: 10px 0;
  &:hover {
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
  }
}
</style>
