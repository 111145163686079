<template>
  <div class="post">
    <div class="container">
      <div class="gutter">
        <img :src="author.pic_url" />
      </div>
      <div>
        <div class="topline">
          <div class="name">{{ author.name }}</div>
          <div class="date">{{ dateTime(post.time) }}</div>
        </div>
        <h1>{{ post.title }}</h1>
        <div class="abovefold">
          <span v-html="post.abovefold" />
          <button
            v-if="
              post.content &&
                post.content.trim().length > 0 &&
                !expandedPosts[post.id]
            "
            @click="expandedPosts[post.id] = true"
          >
            Read more
          </button>
        </div>
        <div
          class="content"
          :class="{ expanded: expandedPosts[post.id] }"
          v-html="post.content"
        ></div>
      </div>
    </div>
    <img
      :class="['hero', { 'mobile-hidden': !!post.hero_image_mobile }]"
      :src="post.hero_image"
    />
    <img class="hero-mobile" :src="post.hero_image_mobile" />
    <div class="social-content">
      <div class="bar">
        <img src="@/assets/social-icons.png" />
        <div class="comment-count">
          {{ comments.length }} comment{{ comments.length === 1 ? "" : "s" }}
        </div>
      </div>
      <div class="comments">
        <chat-message
          class="comment"
          message-type="post"
          highlighted
          v-for="comment in comments"
          :message="comment"
          :key="comment._id"
        />
        <chat-message
          :message="{ user_id, is_typing: true }"
          v-for="user_id in typingUserIds"
          :key="user_id"
        />
      </div>
    </div>
    <chat-bar class="bottom" :post_id="this.post.id" />
  </div>
</template>

<script>
import ChatBar from "./ChatBar";
import ChatMessage from "./ChatMessage";
import formatters from "../formatters";
import { mapGetters, mapState, mapMutations } from "vuex";

export default {
  components: {
    ChatBar,
    ChatMessage,
  },
  props: {
    post: Object,
  },
  data() {
    return {
      expandedPosts: {},
    };
  },
  mixins: [formatters],
  computed: {
    ...mapState(["users", "messages", "typingUserIdsInPostIds"]),
    ...mapGetters(["currentUserAvatarUrl"]),
    author() {
      return this.users[this.post.user_id];
    },
    comments() {
      return Object.values(this.messages).filter(
        (m) => m.post_id && m.post_id === this.post.id
      );
    },
    typingUserIds() {
      return Object.entries(
        (this.typingUserIdsInPostIds[this.post.venue_id] ?? {})[this.post.id] ??
          {}
      )
        .filter((v) => v[1])
        .map((v) => v[0]);
    },
  },
  methods: {
    ...mapMutations(["createPostComment"]),
    onSubmit() {
      if (this.currentComment.trim().length > 0) {
        this.createPostComment({
          text: this.currentComment,
          post_id: this.post.id,
        });
      }
    },
    dateTime(d) {
      const dt = new Date(d);
      const date = dt.toLocaleDateString();
      return [
        new Date().toLocaleDateString() === date ? "Today" : date,
        dt.toLocaleTimeString(),
      ].join(" • ");
    },
  },
};
</script>

<style lang="scss" scoped>
.post {
  padding: 14px 16px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: white;
}

.container {
  display: flex;

  :deep(span.blue) {
    cursor: pointer;
    color: rgb(82, 82, 182);
  }
  :deep(video) {
    width: 100%;
  }
  .gutter {
    margin-right: 8px;
    img {
      width: 32px;
      height: 32px;
      object-fit: cover;
    }
  }
  .topline {
    display: flex;
    align-items: baseline;
    .name,
    .date {
      font-size: 13px;
    }
    .name {
      font-weight: bold;
      margin-right: 4px;
    }
    .date {
      font-size: 11px;
      opacity: 0.5;
    }
  }

  h1,
  p {
    margin: 0;
    padding: 0;
  }
  h1 {
    font-size: 17px;
  }
  .abovefold {
    font-size: 14px;
    button {
      font-weight: bold;
      border: none;
      outline: none;
      background-color: transparent;
      cursor: pointer;
    }
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }
  .content {
    height: 0;
    opacity: 0;
    transition: opacity 0.5s;
    :deep(h1),
    :deep(h2),
    :deep(h3) {
      font-size: 14px;
      margin: 1em 0 0 0;
    }
    font-size: 14px;
    pointer-events: none;
    &.expanded {
      opacity: 1;
      height: auto;
      pointer-events: auto;
    }
    word-break: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
  }
}
img.hero {
  margin-top: 12px;
  display: block;
  object-fit: contain;
  width: 100%;
}
img.hero-mobile {
  margin-top: 12px;
  object-fit: contain;
  width: 100%;
  display: none;
}
@include mobile {
  img.hero.mobile-hidden {
    display: none;
  }
  img.hero-mobile {
    display: block;
  }
}

.social-content {
  margin-top: 12px;
  .bar {
    display: flex;
    justify-content: space-between;
    img {
      height: 17px;
      display: block;
    }
    .comment-count {
      font-size: 11px;
      opacity: 0.5;
    }
  }
}
.comments {
  .comment {
    margin-top: 8px;
  }
}
.bottom {
  margin-top: 8px;
}
</style>
