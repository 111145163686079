let messageIdCounter = 0;
const DEBUG = false;
function createMessage(venue_id, user_id, timeDeltaSeconds, post_id, text, image_url) {
  if (DEBUG) {
    timeDeltaSeconds = 0;
  }
  return {
    id: messageIdCounter++,
    user_id,
    venue_id,
    post_id,
    text,
    _timeOffset: timeDeltaSeconds * 1000,
    image_url
  }
}
const startOffset = 20;
const plushesStartOffset = 0;
export default {
  general: [
    createMessage('general', 3, -3600, null, 'Welcome to the server Larry!'),
    createMessage('general', 5, -1850, null, 'Sooo… I saw that racoon rude is being given a second chance. As it’s a failed campaign. Could we be getting more second chances in the future? I was one of the original backers for racoon rude. If so I have suggestions'),
    createMessage('general', 4, -1000, null, `I loooved this design`),
    createMessage('general', 2, -700, null, `Yeah we are relaunching this with the goal of 200! and we are calling it a second chance we will start giving out second chances to campaigns that were very close since technically it doesn't count as repeating a product (since product wasn't made)`),
    createMessage('general', 9, -550, null, `I actually worked with yoko in launching that one! Niina* technically 😛`),
    createMessage('general', 2, -500, null, `Ginger by Afroninja360 was so close to 200.`),
    createMessage('general', 2, -300, null, `I’d also like to see Antonio and pixels bunni longboi second chance . Having all longboi s is my dream come true. `),
    createMessage('general', 6, -150, null, `I have my fingers crossed 🤞 in the meantime for yoko plush. Even if it might be a next year release. And niina yes.`),
    createMessage('general', 7, startOffset + 10, null, `Hey everyone`),
    createMessage('general', 5, startOffset + 15, null, `Hey Rund`),
    createMessage('general', 8, startOffset + 0, 1, `How do I share my badges?`),
    createMessage('general', 10, startOffset + 28, 1, `On your badges page, just click the “Share Badge” button and you’ll get a link that you can copy anywher`),
    createMessage('general', 13, startOffset + 35, null, `You enjoy your week off Rund?`),
    createMessage('general', 7, startOffset + 40, null, `Yeah, I spent some time with my familiaa! It was funnn haha`),
    createMessage('general', 13, startOffset + 50, null, `Sounds nice!`),
    createMessage('general', 11, startOffset + 55, 1, `Awww, so cute! `),
    createMessage('general', 14, startOffset + 60, null, `Always good to spend time with the fam`),
    createMessage('general', 7, startOffset + 65, null, `For suree but i am back and ready to do some awesome things with my makeship fam`),
    createMessage('general', 12, startOffset + 70, 1, `Right? Pretty rad designs. Some are like super faded too`),
    createMessage('general', 13, startOffset + 75, null, `We look forward to it!`),
  ],
  plushes: [
    createMessage('plushes', 17, -3600, 4, 'SO CUTE'),
    createMessage('plushes', 15, -1850, 4, 'LES GOOOOOOOOO'),
    createMessage('plushes', 16, -1000, 4, `want want want :O`),
    createMessage('plushes', 18, -1000, null, `Am I the only one who is curious how the upcoming bathomet compares to "baby bathomet" from a while ago ?`),
    createMessage('plushes', 9, plushesStartOffset + 5, null, `Hello hello @Legends @MVP @All-Stars & @Chosen, it is time for our weekly spoiler in which we exclusively share a sneak peek of campaigns launching tonight 👀`),
    createMessage('plushes', 19, plushesStartOffset + 8, null, `:0`),
    createMessage('plushes', 20, plushesStartOffset + 10, null, ``, require('@/assets/chat-img-1.png')),
    createMessage('plushes', 14, plushesStartOffset + 15, null, `Poggers`),
    createMessage('plushes', 21, plushesStartOffset + 20, null, `Just got my Zealots!`),
    createMessage('plushes', 18, plushesStartOffset + 30, null, `Given that the campaign launched by now, may we get to know the size of the carbot zelot?`),
    createMessage('plushes', 22, plushesStartOffset + 35, null, `It says on the product page`, require('@/assets/chat-img-2.png')),
    createMessage('plushes', 23, plushesStartOffset + 45, 5, `lucky charms?`),
    createMessage('plushes', 11, plushesStartOffset + 50, 5, `it’s about the same size as the zerling`),
    createMessage('plushes', 18, plushesStartOffset + 55, null, `I am stupid, i didn´t look in that extra tab and was confused about the "this big" bit in the description`),
    createMessage('plushes', 22, plushesStartOffset + 60, null, `Dont worry, I assumed it would be larger due to the description too`),
    createMessage('plushes', 25, plushesStartOffset + 65, null, `what happened to the Gomotion plushie? has the campain ended?`),
    createMessage('plushes', 4, plushesStartOffset + 70, null, `Yup`),
    createMessage('plushes', 13, plushesStartOffset + 75, 5, `are they magically delicious?`),
    createMessage('plushes', 26, plushesStartOffset + 80, null, `It failed if I remember correctly`),
    createMessage('plushes', 25, plushesStartOffset + 85, null, `rip`),
    createMessage('plushes', 24, plushesStartOffset + 90, 5, `Not unless you can eat metal`),
  ],
};