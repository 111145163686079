<template>
  <div class="post-info">
    <img class="connection" src="@/assets/icon-elipse.png" />
    <img class="author" :src="author.pic_url" v-if="author" />
    <div class="name" v-if="author">{{ author.name }}</div>
    <div class="title">{{ summaryText }}</div>
    <img class="hero" :src="post.hero_image" v-if="post.hero_image" />
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    post: {
      type: Object,
    },
  },
  computed: {
    ...mapState(["users"]),
    author() {
      return this.users[this.post.user_id];
    },
    summaryText() {
      const html = this.post?.title ?? this.post?.abovefold ?? "";
      return html.replace(/(<(.*?)>)/gi, "").substring(0, 100);
    },
  },
};
</script>
<style lang="scss" scoped>
.post-info {
  display: flex;
  align-items: center;
  > img {
    flex: 0 0 auto;
    object-fit: contain;
  }
  img.connection {
    height: 13px;
    width: 13px;
    margin-right: 12px;
    margin-left: 20px;
    align-self: flex-end;
  }
  img.author {
    height: 20px;
    width: 20px;
    margin-right: 8px;
    object-fit: contain;
  }
  .name {
    font-size: 12px;
    font-weight: 600;
    margin-right: 4px;
    white-space: nowrap;
  }
  .title {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;

    text-overflow: ellipsis;
    flex: 1 1 auto;
    font-size: 12px;
    margin-right: 8px;
  }
  img.hero {
    height: 38px;
    width: 38px;
    border-radius: 8px;
    object-fit: cover;
  }
}
</style>
