<template>
  <div class="chat">
    <div class="online-list">
      <div class="mobile-bar">
        <div class="button back" @click="$emit('hide-chat')">
          <img src="@/assets/icon-arrow-left.png" />
        </div>
      </div>
      <div class="label">Online</div>
      <img :src="url" v-for="url in onlineAvatars" :key="url" />
      <div class="remaining" v-if="remaining > 0">
        <span>+{{ remaining }}</span>
      </div>
    </div>
    <div class="top-fade"></div>
    <div class="message-pane" ref="messages" @scroll="repaint">
      <chat-message
        class="message"
        :message="{ is_typing: true, user_id }"
        v-for="user_id in currentlyTypingUserIds"
        :key="user_id"
      />
      <post-chat-message-group
        class="message"
        :message="{ post_id }"
        v-for="post_id in postIdsWithNewlyTypingUsers"
        :key="post_id"
      />
      <div
        class="message"
        v-for="message in reversedMessages"
        :key="message._id"
        @click="checkConnect(message, true)"
        :ref="'message' + message.id"
      >
        <post-chat-message-group v-if="message.post_id" :message="message" />
        <chat-message :message="message" v-else />
      </div>
    </div>
    <div class="bottom">
      <chat-bar class="bar" @click="checkConnect(null)" />
    </div>
  </div>
</template>

<script>
import eventBus from "../eventBus";
import ChatMessage from "./ChatMessage";
import ChatBar from "./ChatBar";
import PostChatMessageGroup from "./PostChatMessageGroup.vue";
import { mapState } from "vuex";

export default {
  components: {
    ChatBar,
    ChatMessage,
    PostChatMessageGroup,
  },
  data() {
    return {
      remaining: 12,
      onlineAvatars: [
        require("@/assets/avatar-17.png"),
        require("@/assets/avatar-18.png"),
        require("@/assets/avatar-19.png"),
        require("@/assets/avatar-20.png"),
        require("@/assets/avatar-21.png"),
        require("@/assets/avatar-22.png"),
      ],
      selectedMessage: null,
      clearHandler: null,
    };
  },
  computed: {
    ...mapState([
      "messages",
      "currentVenueId",
      "typingUserIds",
      "typingUserIdsInPostIds",
    ]),
    isSelectedMessageStillVisible() {
      if (!this.selectedMessage) {
        return false;
      }
      return (
        this.reversedMessages.findIndex(
          (x) => x.id === this.selectedMessage.id
        ) !== -1
      );
    },
    reversedMessages() {
      const postIdToLastIndex = {};
      return this.messages
        .reduce((messages, message) => {
          if (message.venue_id !== this.currentVenueId) {
            return messages;
          } else if (!message.post_id) {
            return messages.concat([message]);
          } else {
            if (message.post_id in postIdToLastIndex) {
              messages[postIdToLastIndex[message.post_id]] = message;
              return messages;
            } else {
              postIdToLastIndex[message.post_id] = messages.length;
              return messages.concat([message]);
            }
          }
        }, [])
        .reverse();
    },
    seenPostIds() {
      return new Set(
        this.messages
          .map(({ post_id, venue_id }) =>
            this.currentVenueId === venue_id && post_id != null
              ? post_id.toString()
              : null
          )
          .filter(Boolean)
      );
    },
    currentlyTypingUserIds() {
      return Object.keys(this.typingUserIds[this.currentVenueId] ?? {}).filter(
        (id) => this.typingUserIds[this.currentVenueId][id]
      );
    },
    postIdsWithNewlyTypingUsers() {
      return Object.keys(this.typingUserIdsInPostIds[this.currentVenueId] ?? {})
        .map((post_id) => {
          return post_id;
        })
        .filter(
          (post_id) =>
            !this.seenPostIds.has(post_id) &&
            Object.values(
              this.typingUserIdsInPostIds[this.currentVenueId][post_id]
            ).filter(Boolean).length > 0
        );
    },
  },
  watch: {
    isSelectedMessageStillVisible(newVal, oldVal) {
      if (oldVal && !newVal) {
        // selectedMessage has disappeared, so we need to find a new one.
        this.$nextTick(() => {
          if (this.selectedMessage) {
            let found = this.reversedMessages.find(
              (x) => x.post_id === this.selectedMessage.post_id
            );
            if (found) {
              this.checkConnect(found);
            }
          }
        });
      }
    },
    currentlyTypingUserIds() {
      this.$nextTick(() => {
        if (this.selectedMessage) {
          this.checkConnect(this.selectedMessage);
        }
      });
    },
    reversedMessages() {
      this.$refs.messages.scrollTop = 0;
      this.$nextTick(() => {
        if (this.selectedMessage) {
          this.checkConnect(this.selectedMessage);
        }
      });
    },
  },
  mounted() {
    this.clearHandler = () => {
      this.selectedMessage = null;
    };
    eventBus.$on("clear", this.clearHandler);
  },
  beforeUnmount() {
    eventBus.$off("clear", this.clearHandler);
  },
  methods: {
    repaint() {
      eventBus.$emit("redraw");
    },
    checkConnect(message, isClick) {
      if ((message || {}).post_id) {
        // if we are re-selecting due to click,
        if (isClick) {
          this.$emit("hide-chat");
          // treat all clicks like re-selects.
          eventBus.$emit("connect", {
            message: null,
          });
        }
        this.selectedMessage = message;
        eventBus.$emit("connect", {
          message,
          ref: this.$refs["message" + message.id],
        });
      } else {
        this.selectedMessage = null;
        eventBus.$emit("connect", {
          message,
        });
        eventBus.$emit("clear");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat {
  position: relative;
  background-color: #f0f0f0;
  display: flex;
  height: 100vh;
  flex-direction: column;
  position: relative;
  width: 428px;
  padding-top: 24px;
  flex: 0 0 auto;
  .top-fade {
    background: linear-gradient(
      180deg,
      #f0f0f0 0%,
      rgba(240, 240, 240, 0) 100%
    );
    position: absolute;
    left: 0;
    right: 0;
    top: 56px;
    height: 40px;
  }
  .message-pane {
    flex: 1 1 auto;
    overflow: auto;
    display: flex;
    padding: 24px;
    flex-direction: column-reverse;
  }
  .bottom {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    padding: 16px 14px;
  }
  .online-list {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-right: 16px;
    .label,
    .remaining {
      font-size: 10px;
      font-weight: bold;
      opacity: 0.5;
    }
    .remaining {
      background-color: white;
      height: 32px;
      width: 32px;
      border-radius: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    > img,
    > div {
      margin-left: 4px;
    }
    img {
      height: 32px;
      width: 32px;
    }
  }
  .mobile-bar {
    display: none;
  }
  @include mobile {
    padding-top: 20px;
    height: $device-height;
    .top-fade {
      top: 51px;
    }
    .online-list {
      img {
        height: 24px;
        width: 24px;
      }
    }
    align-self: flex-start;
    div.mobile-bar {
      margin-left: 18px;
      display: flex;
      flex: 1 1 auto;
      .button {
        height: 24px;
        width: 24px;
        > img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
